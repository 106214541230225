@import "../variable.scss";
$colors: (
	primary: #FC624D,
	secondary: #FB5660
);

.skin-orange {
  @mixin title_block($line: true, $fs: 16px) {
    font-size: $fs;
    position: relative;
    font-weight: 800;
    margin: 0;
    text-transform: uppercase;
    @if $line == true {
      margin-bottom: 30px;
    }
    .all {
      position: absolute;
      right: 0;
      font-size: 12px;
      font-weight: 700;
      color: #383838;
      top: 5px;
      i {
        color: color($colors, primary);
        margin-left: 5px;
      }
    }
    @if $line == true {
      &:after {
        content: ' ';
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 15px;
        height: 2px;
        background-color: color($colors, primary);
      }    
    }
  }

  a {
    color: color($colors, primary);
    &:hover, &:focus {
  	  color: color($colors, secondary);
    }
  }

  span.page-link {
    color: color($colors, primary);
    &:hover, &:focus {
  	  color: color($colors, secondary);
    }
  }

  .page-item.active .page-link {
    color: color($colors, primary);
    &:hover, &:focus {
  	  color: color($colors, secondary);
    }
  }

  .badge-primary {
    background-color: color($colors, primary);
  }

  .for-mobile, .for-tablet {
    display: none !important;
  }

  .breadcrumb {
    li {
      a {
        color: color($colors, primary);
      }
    }
  }

  .pagination {
    li.active {
      a {
        background-color: color($colors, primary);
        &:hover, &:focus {
          background-color: color($colors, primary);
        }
      }
    }

    li.active {
      span.page-link {
        background-color: color($colors, primary);
        &:hover, &:focus {
          background-color: color($colors, primary);
        }
      }
    }
  }

  .btn-primary {
    background-color: color($colors, primary);
    color: #fff;
    border-color: color($colors, primary);
    &:disabled,&.disabled, &:hover, &:active, &:active:hover, &:active:focus, &:focus {
      color: #fff;
      background-color: darken(color($colors, primary), 20%);
      border-color: darken(color($colors, primary), 20%);
    }
    &:active:hover {
      background-color: darken(color($colors, primary), 25%);
    }
  }

  .btn-magz {
    color: color($colors, primary);
    border-color: color($colors, primary);
    &:after {
      background-color: color($colors, primary);
    }
    &.white {
      color: #fff;
      &:after {
        background-color: #fff;
      }
      &:focus, &:hover {
        color: color($colors, primary) !important;
      }
    }
  }

  .firstbar {
    .search {
      .help-block {
        div {
          color: color($colors, 'primary');
        }
      }
    }
    .nav-icons {
      li {
        a {
          &:hover {
            color: color($colors, 'primary');
          }
        }
      }
    }
  }

  .headline {
    .item {
      a {
        .badge {
          background-color: color($colors, 'primary');
        }
      }
    }
  }

  .article {
    h2 {
      a {
        &:hover {
          color: color($colors, primary);
        }
      }
    }
  }

  article {
    .category {
      a {
        color: color($colors, primary);
      }
    }
    footer {
      .btn-primary {
        background-color: transparent;
        border-color: color($colors, primary);
        color: color($colors, primary);
        &:hover {
          color: #fff;
          &:after {
            background-color: color($colors, primary);
          }
        }
      }
    }
  }

  .love {
    color: #989898;
    &.active {
      color: color($colors, primary);
    }
  }

  .best-of-the-week {
    .article {
      h2 {
        a {
          &:hover {
            color: color($colors, primary);
          }
        }
      }
    }
  }

  .featured {
    display: inline-block;
    width: 100%;
    position: relative;
    height: 550px;
    margin-bottom: 20px;
    overflow: hidden;
    .inner {
      padding: 20px;
    }
    figure {
      position: absolute;
      z-index: -2;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
    .overlay {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background-color: transparent;
      background-image: linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 56%,rgba(0,0,0,0.6) 93%,rgba(0,0,0,0.59) 100%);
      background-image: -webkit-linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 56%,rgba(0,0,0,0.6) 93%,rgba(0,0,0,0.59) 100%);
    }
    .details {
      padding: 20px 30px;
      position: absolute;
      bottom: 10px;
      h1, .category, .time {
        color: #fff;
        margin: 0;
      }
      .category {
        margin-bottom: 10px;
        a {
          font-size: 12px;
          font-weight: 400;
          padding: 5px 15px;
          display: inline-block;
          border-radius: 30px;
          background-color: #fff;
          color: #000;
          text-decoration: none;
          transition: all .3s;
          -moz-transition: all .3s;
          -webkit-transition: all .3s;
          -o-transition: all .3s;
          &:hover {
            opacity: .8;
          }
        }
      }
      h1 {
        margin-bottom: 5px;
        text-transform: capitalize;
        line-height: 1.4em;
        font-size: 28px;
        font-weight: 700;
        a {
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }

  .topbar {
    ul {
      li {
        a {
          &:hover {
            color: color($colors, primary);
          }
        }
      }
    }
  }

  .menu {
    ul {
      > li {
        > a {
          .badge {
            background-color: color($colors, primary);
          }
          &:after {
            background-color: color($colors, primary);
          }
        }
        .dropdown-menu {
          > li {
            &.active a:after {
              background-color: color($colors, primary);
            }
            > a {
              &:after {              
                opacity: 0;
              }
            }
            &:hover > a {
              &:after {
                background-color: color($colors, primary);
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  .article-list {
    .badge {
      background-color: color($colors, primary);
    }
    .details {
      h1 {
        a {
          &:hover {
            color: color($colors, primary);
          }
        }
      }
    }
  }

  .title-col {
    &:after {
      background-color: color($colors, primary);
    }
  }

  .article-mini {
    .detail {
      .category {
        a {
          color: color($colors, primary);
        }
      }
    }
  }

  .tags-list {
    li {
      a {
        &:after {
          background-color: color($colors, primary);
        }
      }
    }
  }

  aside {
    .aside-title {
      @include title_block();
    }
    .newsletter {
      .icon {
        &.error {
          h1 {
            color: color($colors, primary);
          }
          i:before {
            color: color($colors, primary);
          }
        }
      }
    }
    .nav li {
      button {
        &.active {
          border-top: 2px solid color($colors, primary) !important;
          i {
            color: color($colors, primary);
          }
        }
      }
    }
  }

  .nav-link {
    &:focus,
    &:hover {
      color: color($colors, primary)
    }
  }

  .article-fw {
    .details {
      h1 {
        a {
          &:hover {
            color: color($colors, primary);
          }
        }
      }
    }
  }

  .comments {
    .detail {
      .link {
        a {
          color: color($colors, primary);
        }
      }
    }
  }

  footer.footer {
    border-top: 5px solid color($colors, primary);
    .block {
      .block-title {
        &:after {
          background-color: color($colors, primary);
        }
      }
    }
    .footer-nav-horizontal {
      li {
        a {
          &:hover {
            color: color($colors, primary);
          }
        }
      }
    }
    .footer-nav {
      li {
        a {
          &:hover {
            color: color($colors, primary);
          }
        }
      }
    }
    .tags {
      li {
        a {
          &:hover {
            border-color: color($colors, primary);
          }
        }
      }
    }
    .copyright {
      text-align: center;
      margin-top: 70px;
      color: color($colors, footerText);
      border-top: 1px solid #222;
      padding-top: 35px;
      padding-bottom: 20px;
      font-size: 12px;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 1px;
      a {
        text-decoration: underline;
      }
      div {
        margin-top: 7px;
        i {
          color: color($colors, danger);
          padding: 0 3px;
        }
      }
    }
    .article-mini {
      margin-bottom: 23px;
      .inner {
        border-bottom: 0;
      }
      h1 {
        font-size: 14px;
        a {
          color: #ddd;
        }
      }
      .detail .category a {
        color: color($colors, footerText);
      }
    }
    .btn-magz {
      border-color: #fff;
      border-radius: 3px;
      font-size: 14px;
      padding-left: 13px;
      padding-right: 13px;
    }
  }

  // single
  section.single {
    .line {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    article.main-article {
      header {
        .details {
          li {
            a {
              color: color($colors, primary);
            }
          }
        }
      }

      .main {
        a {
          color: color($colors, primary);
        }
      }

      footer {
        .col:last-child {
          .love {
            &.active {
              background-color: color($colors, primary);
              color: #fff;
              border-color: color($colors, primary);
            }
          }
        }
      }
    }

    footer {
      .tags {
        li {
          a {
            &:hover {
              color: color($colors, primary);
            }
          }
        }
      }
    }
  }

  .sharing {
    ul {
      li {
        &.count {
          color: color($colors, primary);
        }
      }
    }
  }
  .page {
    .element-block {
      .element-title {
        &:after {
          background-color: color($colors, primary);
        }
      }
      .colors {
        li {
          &.primary {
            background-color: color($colors, primary);
          }
          &.secondary {
            background-color: color($colors, secondary);
          }
        }
      }
    }
  }

  .carousel-1 {
    .owl-dots {
      .owl-dot {
        &.active {
          span {
            background-color: color($colors, primary);        
          }
        }
      }
    }
    .owl-nav {
      .owl-prev, .owl-next {
        &:hover {
          background-color: color($colors, primary);
        }
      }
    }
  }

  .nav-tabs-group {
    .nav-tabs-list {
      li {
        &.active {
          a {
            border-bottom: 2px solid color($colors, primary);
          }
        }
      }
    }
    .form-control {
      height: 30px;
      padding: 0;
      margin-top: 3px;
    }
    .nav-tabs-right {
      float: right;
      display: inline-block;
      width: 100px;
    }
  }

  .featured-author {
    .featured-author-body {
      .featured-author-count {
        .item {
          .name {
            color: color($colors, primary);
          }
        }
      }
    }
  }
}