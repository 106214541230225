/*
 * Variable
 */
/* Colors */
.skin-purple a {
  color: #8e44ad;
}
.skin-purple a:hover, .skin-purple a:focus {
  color: #461B93;
}
.skin-purple span.page-link {
  color: #8e44ad;
}
.skin-purple span.page-link:hover, .skin-purple span.page-link:focus {
  color: #461B93;
}
.skin-purple .page-item.active .page-link {
  color: #8e44ad;
}
.skin-purple .page-item.active .page-link:hover, .skin-purple .page-item.active .page-link:focus {
  color: #461B93;
}
.skin-purple .badge-primary {
  background-color: #8e44ad;
}
.skin-purple .for-mobile, .skin-purple .for-tablet {
  display: none !important;
}
.skin-purple .breadcrumb li a {
  color: #8e44ad;
}
.skin-purple .pagination li.active a {
  background-color: #8e44ad;
}
.skin-purple .pagination li.active a:hover, .skin-purple .pagination li.active a:focus {
  background-color: #8e44ad;
}
.skin-purple .pagination li.active span.page-link {
  background-color: #8e44ad;
}
.skin-purple .pagination li.active span.page-link:hover, .skin-purple .pagination li.active span.page-link:focus {
  background-color: #8e44ad;
}
.skin-purple .btn-primary {
  background-color: #8e44ad;
  color: #fff;
  border-color: #8e44ad;
}
.skin-purple .btn-primary:disabled, .skin-purple .btn-primary.disabled, .skin-purple .btn-primary:hover, .skin-purple .btn-primary:active, .skin-purple .btn-primary:active:hover, .skin-purple .btn-primary:active:focus, .skin-purple .btn-primary:focus {
  color: #fff;
  background-color: #522764;
  border-color: #522764;
}
.skin-purple .btn-primary:active:hover {
  background-color: #432051;
}
.skin-purple .btn-magz {
  color: #8e44ad;
  border-color: #8e44ad;
}
.skin-purple .btn-magz:after {
  background-color: #8e44ad;
}
.skin-purple .btn-magz.white {
  color: #fff;
}
.skin-purple .btn-magz.white:after {
  background-color: #fff;
}
.skin-purple .btn-magz.white:focus, .skin-purple .btn-magz.white:hover {
  color: #8e44ad !important;
}
.skin-purple .firstbar .search .help-block div {
  color: #8e44ad;
}
.skin-purple .firstbar .nav-icons li a:hover {
  color: #8e44ad;
}
.skin-purple .headline .item a .badge {
  background-color: #8e44ad;
}
.skin-purple .article h2 a:hover {
  color: #8e44ad;
}
.skin-purple article .category a {
  color: #8e44ad;
}
.skin-purple article footer .btn-primary {
  background-color: transparent;
  border-color: #8e44ad;
  color: #8e44ad;
}
.skin-purple article footer .btn-primary:hover {
  color: #fff;
}
.skin-purple article footer .btn-primary:hover:after {
  background-color: #8e44ad;
}
.skin-purple .love {
  color: #989898;
}
.skin-purple .love.active {
  color: #8e44ad;
}
.skin-purple .best-of-the-week .article h2 a:hover {
  color: #8e44ad;
}
.skin-purple .featured {
  display: inline-block;
  width: 100%;
  position: relative;
  height: 550px;
  margin-bottom: 20px;
  overflow: hidden;
}
.skin-purple .featured .inner {
  padding: 20px;
}
.skin-purple .featured figure {
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.skin-purple .featured .overlay {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: transparent;
  background-image: linear-gradient(transparent 0%, transparent 50%, rgba(0, 0, 0, 0.1) 56%, rgba(0, 0, 0, 0.6) 93%, rgba(0, 0, 0, 0.59) 100%);
  background-image: -webkit-linear-gradient(transparent 0%, transparent 50%, rgba(0, 0, 0, 0.1) 56%, rgba(0, 0, 0, 0.6) 93%, rgba(0, 0, 0, 0.59) 100%);
}
.skin-purple .featured .details {
  padding: 20px 30px;
  position: absolute;
  bottom: 10px;
}
.skin-purple .featured .details h1, .skin-purple .featured .details .category, .skin-purple .featured .details .time {
  color: #fff;
  margin: 0;
}
.skin-purple .featured .details .category {
  margin-bottom: 10px;
}
.skin-purple .featured .details .category a {
  font-size: 12px;
  font-weight: 400;
  padding: 5px 15px;
  display: inline-block;
  border-radius: 30px;
  background-color: #fff;
  color: #000;
  text-decoration: none;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.skin-purple .featured .details .category a:hover {
  opacity: 0.8;
}
.skin-purple .featured .details h1 {
  margin-bottom: 5px;
  text-transform: capitalize;
  line-height: 1.4em;
  font-size: 28px;
  font-weight: 700;
}
.skin-purple .featured .details h1 a {
  text-decoration: none;
  color: #fff;
}
.skin-purple .topbar ul li a:hover {
  color: #8e44ad;
}
.skin-purple .menu ul > li > a .badge {
  background-color: #8e44ad;
}
.skin-purple .menu ul > li > a:after {
  background-color: #8e44ad;
}
.skin-purple .menu ul > li .dropdown-menu > li.active a:after {
  background-color: #8e44ad;
}
.skin-purple .menu ul > li .dropdown-menu > li > a:after {
  opacity: 0;
}
.skin-purple .menu ul > li .dropdown-menu > li:hover > a:after {
  background-color: #8e44ad;
  opacity: 1;
}
.skin-purple .article-list .badge {
  background-color: #8e44ad;
}
.skin-purple .article-list .details h1 a:hover {
  color: #8e44ad;
}
.skin-purple .title-col:after {
  background-color: #8e44ad;
}
.skin-purple .article-mini .detail .category a {
  color: #8e44ad;
}
.skin-purple .tags-list li a:after {
  background-color: #8e44ad;
}
.skin-purple aside .aside-title {
  font-size: 16px;
  position: relative;
  font-weight: 800;
  margin: 0;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.skin-purple aside .aside-title .all {
  position: absolute;
  right: 0;
  font-size: 12px;
  font-weight: 700;
  color: #383838;
  top: 5px;
}
.skin-purple aside .aside-title .all i {
  color: #8e44ad;
  margin-left: 5px;
}
.skin-purple aside .aside-title:after {
  content: " ";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 15px;
  height: 2px;
  background-color: #8e44ad;
}
.skin-purple aside .newsletter .icon.error h1 {
  color: #8e44ad;
}
.skin-purple aside .newsletter .icon.error i:before {
  color: #8e44ad;
}
.skin-purple aside .nav li button.active {
  border-top: 2px solid #8e44ad !important;
}
.skin-purple aside .nav li button.active i {
  color: #8e44ad;
}
.skin-purple .nav-link:focus, .skin-purple .nav-link:hover {
  color: #8e44ad;
}
.skin-purple .article-fw .details h1 a:hover {
  color: #8e44ad;
}
.skin-purple .comments .detail .link a {
  color: #8e44ad;
}
.skin-purple footer.footer {
  border-top: 5px solid #8e44ad;
}
.skin-purple footer.footer .block .block-title:after {
  background-color: #8e44ad;
}
.skin-purple footer.footer .footer-nav-horizontal li a:hover {
  color: #8e44ad;
}
.skin-purple footer.footer .footer-nav li a:hover {
  color: #8e44ad;
}
.skin-purple footer.footer .tags li a:hover {
  border-color: #8e44ad;
}
.skin-purple footer.footer .copyright {
  text-align: center;
  margin-top: 70px;
  border-top: 1px solid #222;
  padding-top: 35px;
  padding-bottom: 20px;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.skin-purple footer.footer .copyright a {
  text-decoration: underline;
}
.skin-purple footer.footer .copyright div {
  margin-top: 7px;
}
.skin-purple footer.footer .copyright div i {
  padding: 0 3px;
}
.skin-purple footer.footer .article-mini {
  margin-bottom: 23px;
}
.skin-purple footer.footer .article-mini .inner {
  border-bottom: 0;
}
.skin-purple footer.footer .article-mini h1 {
  font-size: 14px;
}
.skin-purple footer.footer .article-mini h1 a {
  color: #ddd;
}
.skin-purple footer.footer .btn-magz {
  border-color: #fff;
  border-radius: 3px;
  font-size: 14px;
  padding-left: 13px;
  padding-right: 13px;
}
.skin-purple section.single .line {
  margin-top: 30px;
  margin-bottom: 30px;
}
.skin-purple section.single article.main-article header .details li a {
  color: #8e44ad;
}
.skin-purple section.single article.main-article .main a {
  color: #8e44ad;
}
.skin-purple section.single article.main-article footer .col:last-child .love.active {
  background-color: #8e44ad;
  color: #fff;
  border-color: #8e44ad;
}
.skin-purple section.single footer .tags li a:hover {
  color: #8e44ad;
}
.skin-purple .sharing ul li.count {
  color: #8e44ad;
}
.skin-purple .page .element-block .element-title:after {
  background-color: #8e44ad;
}
.skin-purple .page .element-block .colors li.primary {
  background-color: #8e44ad;
}
.skin-purple .page .element-block .colors li.secondary {
  background-color: #461B93;
}
.skin-purple .carousel-1 .owl-dots .owl-dot.active span {
  background-color: #8e44ad;
}
.skin-purple .carousel-1 .owl-nav .owl-prev:hover, .skin-purple .carousel-1 .owl-nav .owl-next:hover {
  background-color: #8e44ad;
}
.skin-purple .nav-tabs-group .nav-tabs-list li.active a {
  border-bottom: 2px solid #8e44ad;
}
.skin-purple .nav-tabs-group .form-control {
  height: 30px;
  padding: 0;
  margin-top: 3px;
}
.skin-purple .nav-tabs-group .nav-tabs-right {
  float: right;
  display: inline-block;
  width: 100px;
}
.skin-purple .featured-author .featured-author-body .featured-author-count .item .name {
  color: #8e44ad;
}

/*
 * Variable
 */
/* Colors */
.skin-blue a {
  color: #626EEF;
}
.skin-blue a:hover, .skin-blue a:focus {
  color: #5F72B2;
}
.skin-blue span.page-link {
  color: #626EEF;
}
.skin-blue span.page-link:hover, .skin-blue span.page-link:focus {
  color: #5F72B2;
}
.skin-blue .page-item.active .page-link {
  color: #626EEF;
}
.skin-blue .page-item.active .page-link:hover, .skin-blue .page-item.active .page-link:focus {
  color: #5F72B2;
}
.skin-blue .badge-primary {
  background-color: #626EEF;
}
.skin-blue .for-mobile, .skin-blue .for-tablet {
  display: none !important;
}
.skin-blue .breadcrumb li a {
  color: #626EEF;
}
.skin-blue .pagination li.active a {
  background-color: #626EEF;
}
.skin-blue .pagination li.active a:hover, .skin-blue .pagination li.active a:focus {
  background-color: #626EEF;
}
.skin-blue .pagination li.active span.page-link {
  background-color: #626EEF;
}
.skin-blue .pagination li.active span.page-link:hover, .skin-blue .pagination li.active span.page-link:focus {
  background-color: #626EEF;
}
.skin-blue .btn-primary {
  background-color: #626EEF;
  color: #fff;
  border-color: #626EEF;
}
.skin-blue .btn-primary:disabled, .skin-blue .btn-primary.disabled, .skin-blue .btn-primary:hover, .skin-blue .btn-primary:active, .skin-blue .btn-primary:active:hover, .skin-blue .btn-primary:active:focus, .skin-blue .btn-primary:focus {
  color: #fff;
  background-color: #1626d5;
  border-color: #1626d5;
}
.skin-blue .btn-primary:active:hover {
  background-color: #1322be;
}
.skin-blue .btn-magz {
  color: #626EEF;
  border-color: #626EEF;
}
.skin-blue .btn-magz:after {
  background-color: #626EEF;
}
.skin-blue .btn-magz.white {
  color: #fff;
}
.skin-blue .btn-magz.white:after {
  background-color: #fff;
}
.skin-blue .btn-magz.white:focus, .skin-blue .btn-magz.white:hover {
  color: #626EEF !important;
}
.skin-blue .firstbar .search .help-block div {
  color: #626EEF;
}
.skin-blue .firstbar .nav-icons li a:hover {
  color: #626EEF;
}
.skin-blue .headline .item a .badge {
  background-color: #626EEF;
}
.skin-blue .article h2 a:hover {
  color: #626EEF;
}
.skin-blue article .category a {
  color: #626EEF;
}
.skin-blue article footer .btn-primary {
  background-color: transparent;
  border-color: #626EEF;
  color: #626EEF;
}
.skin-blue article footer .btn-primary:hover {
  color: #fff;
}
.skin-blue article footer .btn-primary:hover:after {
  background-color: #626EEF;
}
.skin-blue .love {
  color: #989898;
}
.skin-blue .love.active {
  color: #626EEF;
}
.skin-blue .best-of-the-week .article h2 a:hover {
  color: #626EEF;
}
.skin-blue .featured {
  display: inline-block;
  width: 100%;
  position: relative;
  height: 550px;
  margin-bottom: 20px;
  overflow: hidden;
}
.skin-blue .featured .inner {
  padding: 20px;
}
.skin-blue .featured figure {
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.skin-blue .featured .overlay {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: transparent;
  background-image: linear-gradient(transparent 0%, transparent 50%, rgba(0, 0, 0, 0.1) 56%, rgba(0, 0, 0, 0.6) 93%, rgba(0, 0, 0, 0.59) 100%);
  background-image: -webkit-linear-gradient(transparent 0%, transparent 50%, rgba(0, 0, 0, 0.1) 56%, rgba(0, 0, 0, 0.6) 93%, rgba(0, 0, 0, 0.59) 100%);
}
.skin-blue .featured .details {
  padding: 20px 30px;
  position: absolute;
  bottom: 10px;
}
.skin-blue .featured .details h1, .skin-blue .featured .details .category, .skin-blue .featured .details .time {
  color: #fff;
  margin: 0;
}
.skin-blue .featured .details .category {
  margin-bottom: 10px;
}
.skin-blue .featured .details .category a {
  font-size: 12px;
  font-weight: 400;
  padding: 5px 15px;
  display: inline-block;
  border-radius: 30px;
  background-color: #fff;
  color: #000;
  text-decoration: none;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.skin-blue .featured .details .category a:hover {
  opacity: 0.8;
}
.skin-blue .featured .details h1 {
  margin-bottom: 5px;
  text-transform: capitalize;
  line-height: 1.4em;
  font-size: 28px;
  font-weight: 700;
}
.skin-blue .featured .details h1 a {
  text-decoration: none;
  color: #fff;
}
.skin-blue .topbar ul li a:hover {
  color: #626EEF;
}
.skin-blue .menu ul > li > a .badge {
  background-color: #626EEF;
}
.skin-blue .menu ul > li > a:after {
  background-color: #626EEF;
}
.skin-blue .menu ul > li .dropdown-menu > li.active a:after {
  background-color: #626EEF;
}
.skin-blue .menu ul > li .dropdown-menu > li > a:after {
  opacity: 0;
}
.skin-blue .menu ul > li .dropdown-menu > li:hover > a:after {
  background-color: #626EEF;
  opacity: 1;
}
.skin-blue .article-list .badge {
  background-color: #626EEF;
}
.skin-blue .article-list .details h1 a:hover {
  color: #626EEF;
}
.skin-blue .title-col:after {
  background-color: #626EEF;
}
.skin-blue .article-mini .detail .category a {
  color: #626EEF;
}
.skin-blue .tags-list li a:after {
  background-color: #626EEF;
}
.skin-blue aside .aside-title {
  font-size: 16px;
  position: relative;
  font-weight: 800;
  margin: 0;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.skin-blue aside .aside-title .all {
  position: absolute;
  right: 0;
  font-size: 12px;
  font-weight: 700;
  color: #383838;
  top: 5px;
}
.skin-blue aside .aside-title .all i {
  color: #626EEF;
  margin-left: 5px;
}
.skin-blue aside .aside-title:after {
  content: " ";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 15px;
  height: 2px;
  background-color: #626EEF;
}
.skin-blue aside .newsletter .icon.error h1 {
  color: #626EEF;
}
.skin-blue aside .newsletter .icon.error i:before {
  color: #626EEF;
}
.skin-blue aside .nav li button.active {
  border-top: 2px solid #626EEF !important;
}
.skin-blue aside .nav li button.active i {
  color: #626EEF;
}
.skin-blue .nav-link:focus, .skin-blue .nav-link:hover {
  color: #626EEF;
}
.skin-blue .article-fw .details h1 a:hover {
  color: #626EEF;
}
.skin-blue .comments .detail .link a {
  color: #626EEF;
}
.skin-blue footer.footer {
  border-top: 5px solid #626EEF;
}
.skin-blue footer.footer .block .block-title:after {
  background-color: #626EEF;
}
.skin-blue footer.footer .footer-nav-horizontal li a:hover {
  color: #626EEF;
}
.skin-blue footer.footer .footer-nav li a:hover {
  color: #626EEF;
}
.skin-blue footer.footer .tags li a:hover {
  border-color: #626EEF;
}
.skin-blue footer.footer .copyright {
  text-align: center;
  margin-top: 70px;
  border-top: 1px solid #222;
  padding-top: 35px;
  padding-bottom: 20px;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.skin-blue footer.footer .copyright a {
  text-decoration: underline;
}
.skin-blue footer.footer .copyright div {
  margin-top: 7px;
}
.skin-blue footer.footer .copyright div i {
  padding: 0 3px;
}
.skin-blue footer.footer .article-mini {
  margin-bottom: 23px;
}
.skin-blue footer.footer .article-mini .inner {
  border-bottom: 0;
}
.skin-blue footer.footer .article-mini h1 {
  font-size: 14px;
}
.skin-blue footer.footer .article-mini h1 a {
  color: #ddd;
}
.skin-blue footer.footer .btn-magz {
  border-color: #fff;
  border-radius: 3px;
  font-size: 14px;
  padding-left: 13px;
  padding-right: 13px;
}
.skin-blue section.single .line {
  margin-top: 30px;
  margin-bottom: 30px;
}
.skin-blue section.single article.main-article header .details li a {
  color: #626EEF;
}
.skin-blue section.single article.main-article .main a {
  color: #626EEF;
}
.skin-blue section.single article.main-article footer .col:last-child .love.active {
  background-color: #626EEF;
  color: #fff;
  border-color: #626EEF;
}
.skin-blue section.single footer .tags li a:hover {
  color: #626EEF;
}
.skin-blue .sharing ul li.count {
  color: #626EEF;
}
.skin-blue .page .element-block .element-title:after {
  background-color: #626EEF;
}
.skin-blue .page .element-block .colors li.primary {
  background-color: #626EEF;
}
.skin-blue .page .element-block .colors li.secondary {
  background-color: #5F72B2;
}
.skin-blue .carousel-1 .owl-dots .owl-dot.active span {
  background-color: #626EEF;
}
.skin-blue .carousel-1 .owl-nav .owl-prev:hover, .skin-blue .carousel-1 .owl-nav .owl-next:hover {
  background-color: #626EEF;
}
.skin-blue .nav-tabs-group .nav-tabs-list li.active a {
  border-bottom: 2px solid #626EEF;
}
.skin-blue .nav-tabs-group .form-control {
  height: 30px;
  padding: 0;
  margin-top: 3px;
}
.skin-blue .nav-tabs-group .nav-tabs-right {
  float: right;
  display: inline-block;
  width: 100px;
}
.skin-blue .featured-author .featured-author-body .featured-author-count .item .name {
  color: #626EEF;
}

/*
 * Variable
 */
/* Colors */
.skin-orange a {
  color: #FC624D;
}
.skin-orange a:hover, .skin-orange a:focus {
  color: #FB5660;
}
.skin-orange span.page-link {
  color: #FC624D;
}
.skin-orange span.page-link:hover, .skin-orange span.page-link:focus {
  color: #FB5660;
}
.skin-orange .page-item.active .page-link {
  color: #FC624D;
}
.skin-orange .page-item.active .page-link:hover, .skin-orange .page-item.active .page-link:focus {
  color: #FB5660;
}
.skin-orange .badge-primary {
  background-color: #FC624D;
}
.skin-orange .for-mobile, .skin-orange .for-tablet {
  display: none !important;
}
.skin-orange .breadcrumb li a {
  color: #FC624D;
}
.skin-orange .pagination li.active a {
  background-color: #FC624D;
}
.skin-orange .pagination li.active a:hover, .skin-orange .pagination li.active a:focus {
  background-color: #FC624D;
}
.skin-orange .pagination li.active span.page-link {
  background-color: #FC624D;
}
.skin-orange .pagination li.active span.page-link:hover, .skin-orange .pagination li.active span.page-link:focus {
  background-color: #FC624D;
}
.skin-orange .btn-primary {
  background-color: #FC624D;
  color: #fff;
  border-color: #FC624D;
}
.skin-orange .btn-primary:disabled, .skin-orange .btn-primary.disabled, .skin-orange .btn-primary:hover, .skin-orange .btn-primary:active, .skin-orange .btn-primary:active:hover, .skin-orange .btn-primary:active:focus, .skin-orange .btn-primary:focus {
  color: #fff;
  background-color: #df1e04;
  border-color: #df1e04;
}
.skin-orange .btn-primary:active:hover {
  background-color: #c61b03;
}
.skin-orange .btn-magz {
  color: #FC624D;
  border-color: #FC624D;
}
.skin-orange .btn-magz:after {
  background-color: #FC624D;
}
.skin-orange .btn-magz.white {
  color: #fff;
}
.skin-orange .btn-magz.white:after {
  background-color: #fff;
}
.skin-orange .btn-magz.white:focus, .skin-orange .btn-magz.white:hover {
  color: #FC624D !important;
}
.skin-orange .firstbar .search .help-block div {
  color: #FC624D;
}
.skin-orange .firstbar .nav-icons li a:hover {
  color: #FC624D;
}
.skin-orange .headline .item a .badge {
  background-color: #FC624D;
}
.skin-orange .article h2 a:hover {
  color: #FC624D;
}
.skin-orange article .category a {
  color: #FC624D;
}
.skin-orange article footer .btn-primary {
  background-color: transparent;
  border-color: #FC624D;
  color: #FC624D;
}
.skin-orange article footer .btn-primary:hover {
  color: #fff;
}
.skin-orange article footer .btn-primary:hover:after {
  background-color: #FC624D;
}
.skin-orange .love {
  color: #989898;
}
.skin-orange .love.active {
  color: #FC624D;
}
.skin-orange .best-of-the-week .article h2 a:hover {
  color: #FC624D;
}
.skin-orange .featured {
  display: inline-block;
  width: 100%;
  position: relative;
  height: 550px;
  margin-bottom: 20px;
  overflow: hidden;
}
.skin-orange .featured .inner {
  padding: 20px;
}
.skin-orange .featured figure {
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.skin-orange .featured .overlay {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: transparent;
  background-image: linear-gradient(transparent 0%, transparent 50%, rgba(0, 0, 0, 0.1) 56%, rgba(0, 0, 0, 0.6) 93%, rgba(0, 0, 0, 0.59) 100%);
  background-image: -webkit-linear-gradient(transparent 0%, transparent 50%, rgba(0, 0, 0, 0.1) 56%, rgba(0, 0, 0, 0.6) 93%, rgba(0, 0, 0, 0.59) 100%);
}
.skin-orange .featured .details {
  padding: 20px 30px;
  position: absolute;
  bottom: 10px;
}
.skin-orange .featured .details h1, .skin-orange .featured .details .category, .skin-orange .featured .details .time {
  color: #fff;
  margin: 0;
}
.skin-orange .featured .details .category {
  margin-bottom: 10px;
}
.skin-orange .featured .details .category a {
  font-size: 12px;
  font-weight: 400;
  padding: 5px 15px;
  display: inline-block;
  border-radius: 30px;
  background-color: #fff;
  color: #000;
  text-decoration: none;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.skin-orange .featured .details .category a:hover {
  opacity: 0.8;
}
.skin-orange .featured .details h1 {
  margin-bottom: 5px;
  text-transform: capitalize;
  line-height: 1.4em;
  font-size: 28px;
  font-weight: 700;
}
.skin-orange .featured .details h1 a {
  text-decoration: none;
  color: #fff;
}
.skin-orange .topbar ul li a:hover {
  color: #FC624D;
}
.skin-orange .menu ul > li > a .badge {
  background-color: #FC624D;
}
.skin-orange .menu ul > li > a:after {
  background-color: #FC624D;
}
.skin-orange .menu ul > li .dropdown-menu > li.active a:after {
  background-color: #FC624D;
}
.skin-orange .menu ul > li .dropdown-menu > li > a:after {
  opacity: 0;
}
.skin-orange .menu ul > li .dropdown-menu > li:hover > a:after {
  background-color: #FC624D;
  opacity: 1;
}
.skin-orange .article-list .badge {
  background-color: #FC624D;
}
.skin-orange .article-list .details h1 a:hover {
  color: #FC624D;
}
.skin-orange .title-col:after {
  background-color: #FC624D;
}
.skin-orange .article-mini .detail .category a {
  color: #FC624D;
}
.skin-orange .tags-list li a:after {
  background-color: #FC624D;
}
.skin-orange aside .aside-title {
  font-size: 16px;
  position: relative;
  font-weight: 800;
  margin: 0;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.skin-orange aside .aside-title .all {
  position: absolute;
  right: 0;
  font-size: 12px;
  font-weight: 700;
  color: #383838;
  top: 5px;
}
.skin-orange aside .aside-title .all i {
  color: #FC624D;
  margin-left: 5px;
}
.skin-orange aside .aside-title:after {
  content: " ";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 15px;
  height: 2px;
  background-color: #FC624D;
}
.skin-orange aside .newsletter .icon.error h1 {
  color: #FC624D;
}
.skin-orange aside .newsletter .icon.error i:before {
  color: #FC624D;
}
.skin-orange aside .nav li button.active {
  border-top: 2px solid #FC624D !important;
}
.skin-orange aside .nav li button.active i {
  color: #FC624D;
}
.skin-orange .nav-link:focus, .skin-orange .nav-link:hover {
  color: #FC624D;
}
.skin-orange .article-fw .details h1 a:hover {
  color: #FC624D;
}
.skin-orange .comments .detail .link a {
  color: #FC624D;
}
.skin-orange footer.footer {
  border-top: 5px solid #FC624D;
}
.skin-orange footer.footer .block .block-title:after {
  background-color: #FC624D;
}
.skin-orange footer.footer .footer-nav-horizontal li a:hover {
  color: #FC624D;
}
.skin-orange footer.footer .footer-nav li a:hover {
  color: #FC624D;
}
.skin-orange footer.footer .tags li a:hover {
  border-color: #FC624D;
}
.skin-orange footer.footer .copyright {
  text-align: center;
  margin-top: 70px;
  border-top: 1px solid #222;
  padding-top: 35px;
  padding-bottom: 20px;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.skin-orange footer.footer .copyright a {
  text-decoration: underline;
}
.skin-orange footer.footer .copyright div {
  margin-top: 7px;
}
.skin-orange footer.footer .copyright div i {
  padding: 0 3px;
}
.skin-orange footer.footer .article-mini {
  margin-bottom: 23px;
}
.skin-orange footer.footer .article-mini .inner {
  border-bottom: 0;
}
.skin-orange footer.footer .article-mini h1 {
  font-size: 14px;
}
.skin-orange footer.footer .article-mini h1 a {
  color: #ddd;
}
.skin-orange footer.footer .btn-magz {
  border-color: #fff;
  border-radius: 3px;
  font-size: 14px;
  padding-left: 13px;
  padding-right: 13px;
}
.skin-orange section.single .line {
  margin-top: 30px;
  margin-bottom: 30px;
}
.skin-orange section.single article.main-article header .details li a {
  color: #FC624D;
}
.skin-orange section.single article.main-article .main a {
  color: #FC624D;
}
.skin-orange section.single article.main-article footer .col:last-child .love.active {
  background-color: #FC624D;
  color: #fff;
  border-color: #FC624D;
}
.skin-orange section.single footer .tags li a:hover {
  color: #FC624D;
}
.skin-orange .sharing ul li.count {
  color: #FC624D;
}
.skin-orange .page .element-block .element-title:after {
  background-color: #FC624D;
}
.skin-orange .page .element-block .colors li.primary {
  background-color: #FC624D;
}
.skin-orange .page .element-block .colors li.secondary {
  background-color: #FB5660;
}
.skin-orange .carousel-1 .owl-dots .owl-dot.active span {
  background-color: #FC624D;
}
.skin-orange .carousel-1 .owl-nav .owl-prev:hover, .skin-orange .carousel-1 .owl-nav .owl-next:hover {
  background-color: #FC624D;
}
.skin-orange .nav-tabs-group .nav-tabs-list li.active a {
  border-bottom: 2px solid #FC624D;
}
.skin-orange .nav-tabs-group .form-control {
  height: 30px;
  padding: 0;
  margin-top: 3px;
}
.skin-orange .nav-tabs-group .nav-tabs-right {
  float: right;
  display: inline-block;
  width: 100px;
}
.skin-orange .featured-author .featured-author-body .featured-author-count .item .name {
  color: #FC624D;
}

/*
 * Variable
 */
/* Colors */
/* Colors */
.skin-dark a {
  color: #313131;
}
.skin-dark a:hover, .skin-dark a:focus {
  color: #181818;
}
.skin-dark span.page-link {
  color: #313131;
}
.skin-dark span.page-link:hover, .skin-dark span.page-link:focus {
  color: #181818;
}
.skin-dark .page-item.active .page-link {
  color: #313131;
}
.skin-dark .page-item.active .page-link:hover, .skin-dark .page-item.active .page-link:focus {
  color: #181818;
}
.skin-dark .badge-primary {
  background-color: #313131;
}
.skin-dark .for-mobile, .skin-dark .for-tablet {
  display: none !important;
}
.skin-dark .breadcrumb li a {
  color: #313131;
}
.skin-dark .pagination li.active a {
  background-color: #313131;
}
.skin-dark .pagination li.active a:hover, .skin-dark .pagination li.active a:focus {
  background-color: #313131;
}
.skin-dark .pagination li.active span.page-link {
  background-color: #313131;
}
.skin-dark .pagination li.active span.page-link:hover, .skin-dark .pagination li.active span.page-link:focus {
  background-color: #313131;
}
.skin-dark .btn-primary {
  background-color: #313131;
  color: #fff;
  border-color: #313131;
}
.skin-dark .btn-primary:disabled, .skin-dark .btn-primary.disabled, .skin-dark .btn-primary:hover, .skin-dark .btn-primary:active, .skin-dark .btn-primary:active:hover, .skin-dark .btn-primary:active:focus, .skin-dark .btn-primary:focus {
  color: #fff;
  background-color: black;
  border-color: black;
}
.skin-dark .btn-primary:active:hover {
  background-color: black;
}
.skin-dark .btn-magz {
  color: #313131;
  border-color: #313131;
}
.skin-dark .btn-magz:after {
  background-color: #313131;
}
.skin-dark .btn-magz.white {
  color: #fff;
}
.skin-dark .btn-magz.white:after {
  background-color: #fff;
}
.skin-dark .btn-magz.white:focus, .skin-dark .btn-magz.white:hover {
  color: #313131 !important;
}
.skin-dark .firstbar .search .help-block div {
  color: #313131;
}
.skin-dark .firstbar .nav-icons li a:hover {
  color: #313131;
}
.skin-dark .headline .item a .badge {
  background-color: #313131;
}
.skin-dark .article h2 a:hover {
  color: #313131;
}
.skin-dark article .category a {
  color: #313131;
}
.skin-dark article footer .btn-primary {
  background-color: transparent;
  border-color: #313131;
  color: #313131;
}
.skin-dark article footer .btn-primary:hover {
  color: #fff;
}
.skin-dark article footer .btn-primary:hover:after {
  background-color: #313131;
}
.skin-dark .love {
  color: #989898;
}
.skin-dark .love.active {
  color: #313131;
}
.skin-dark .best-of-the-week .article h2 a:hover {
  color: #313131;
}
.skin-dark .featured {
  display: inline-block;
  width: 100%;
  position: relative;
  height: 550px;
  margin-bottom: 20px;
  overflow: hidden;
}
.skin-dark .featured .inner {
  padding: 20px;
}
.skin-dark .featured figure {
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.skin-dark .featured .overlay {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: transparent;
  background-image: linear-gradient(transparent 0%, transparent 50%, rgba(0, 0, 0, 0.1) 56%, rgba(0, 0, 0, 0.6) 93%, rgba(0, 0, 0, 0.59) 100%);
  background-image: -webkit-linear-gradient(transparent 0%, transparent 50%, rgba(0, 0, 0, 0.1) 56%, rgba(0, 0, 0, 0.6) 93%, rgba(0, 0, 0, 0.59) 100%);
}
.skin-dark .featured .details {
  padding: 20px 30px;
  position: absolute;
  bottom: 10px;
}
.skin-dark .featured .details h1, .skin-dark .featured .details .category, .skin-dark .featured .details .time {
  color: #fff;
  margin: 0;
}
.skin-dark .featured .details .category {
  margin-bottom: 10px;
}
.skin-dark .featured .details .category a {
  font-size: 12px;
  font-weight: 400;
  padding: 5px 15px;
  display: inline-block;
  border-radius: 30px;
  background-color: #fff;
  color: #000;
  text-decoration: none;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.skin-dark .featured .details .category a:hover {
  opacity: 0.8;
}
.skin-dark .featured .details h1 {
  margin-bottom: 5px;
  text-transform: capitalize;
  line-height: 1.4em;
  font-size: 28px;
  font-weight: 700;
}
.skin-dark .featured .details h1 a {
  text-decoration: none;
  color: #fff;
}
.skin-dark .topbar ul li a:hover {
  color: #313131;
}
.skin-dark .menu ul > li > a .badge {
  background-color: #313131;
}
.skin-dark .menu ul > li > a:after {
  background-color: #313131;
}
.skin-dark .menu ul > li .dropdown-menu > li.active a:after {
  background-color: #313131;
}
.skin-dark .menu ul > li .dropdown-menu > li > a:after {
  opacity: 0;
}
.skin-dark .menu ul > li .dropdown-menu > li:hover > a:after {
  background-color: #313131;
  opacity: 1;
}
.skin-dark .article-list .badge {
  background-color: #313131;
}
.skin-dark .article-list .details h1 a:hover {
  color: #313131;
}
.skin-dark .title-col:after {
  background-color: #313131;
}
.skin-dark .article-mini .detail .category a {
  color: #313131;
}
.skin-dark .tags-list li a:after {
  background-color: #313131;
}
.skin-dark aside .aside-title {
  font-size: 16px;
  position: relative;
  font-weight: 800;
  margin: 0;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.skin-dark aside .aside-title .all {
  position: absolute;
  right: 0;
  font-size: 12px;
  font-weight: 700;
  color: #9C9C9C;
  top: 5px;
}
.skin-dark aside .aside-title .all i {
  color: #313131;
  margin-left: 5px;
}
.skin-dark aside .aside-title:after {
  content: " ";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 15px;
  height: 2px;
  background-color: #313131;
}
.skin-dark aside .newsletter .icon.error h1 {
  color: #313131;
}
.skin-dark aside .newsletter .icon.error i:before {
  color: #313131;
}
.skin-dark aside .nav li button.active {
  border-top: 2px solid #313131 !important;
}
.skin-dark aside .nav li button.active i {
  color: #313131;
}
.skin-dark .nav-link:focus, .skin-dark .nav-link:hover {
  color: #313131;
}
.skin-dark .article-fw .details h1 a:hover {
  color: #313131;
}
.skin-dark .comments .detail .link a {
  color: #313131;
}
.skin-dark footer.footer {
  border-top: 5px solid #313131;
}
.skin-dark footer.footer .block .block-title:after {
  background-color: #313131;
}
.skin-dark footer.footer .footer-nav-horizontal li a:hover {
  color: #313131;
}
.skin-dark footer.footer .footer-nav li a:hover {
  color: #313131;
}
.skin-dark footer.footer .tags li a:hover {
  border-color: #313131;
}
.skin-dark footer.footer .copyright {
  text-align: center;
  margin-top: 70px;
  border-top: 1px solid #222;
  padding-top: 35px;
  padding-bottom: 20px;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.skin-dark footer.footer .copyright a {
  text-decoration: underline;
}
.skin-dark footer.footer .copyright div {
  margin-top: 7px;
}
.skin-dark footer.footer .copyright div i {
  padding: 0 3px;
}
.skin-dark footer.footer .article-mini {
  margin-bottom: 23px;
}
.skin-dark footer.footer .article-mini .inner {
  border-bottom: 0;
}
.skin-dark footer.footer .article-mini h1 {
  font-size: 14px;
}
.skin-dark footer.footer .article-mini h1 a {
  color: #ddd;
}
.skin-dark footer.footer .btn-magz {
  border-color: #fff;
  border-radius: 3px;
  font-size: 14px;
  padding-left: 13px;
  padding-right: 13px;
}
.skin-dark section.single .line {
  margin-top: 30px;
  margin-bottom: 30px;
}
.skin-dark section.single article.main-article header .details li a {
  color: #313131;
}
.skin-dark section.single article.main-article .main a {
  color: #313131;
}
.skin-dark section.single article.main-article footer .col:last-child .love.active {
  background-color: #313131;
  color: #fff;
  border-color: #313131;
}
.skin-dark section.single footer .tags li a:hover {
  color: #313131;
}
.skin-dark .sharing ul li.count {
  color: #313131;
}
.skin-dark .page .element-block .element-title:after {
  background-color: #313131;
}
.skin-dark .page .element-block .colors li.primary {
  background-color: #313131;
}
.skin-dark .page .element-block .colors li.secondary {
  background-color: #181818;
}
.skin-dark .carousel-1 .owl-dots .owl-dot.active span {
  background-color: #313131;
}
.skin-dark .carousel-1 .owl-nav .owl-prev:hover, .skin-dark .carousel-1 .owl-nav .owl-next:hover {
  background-color: #313131;
}
.skin-dark .nav-tabs-group .nav-tabs-list li.active a {
  border-bottom: 2px solid #313131;
}
.skin-dark .nav-tabs-group .form-control {
  height: 30px;
  padding: 0;
  margin-top: 3px;
}
.skin-dark .nav-tabs-group .nav-tabs-right {
  float: right;
  display: inline-block;
  width: 100px;
}
.skin-dark .featured-author .featured-author-body .featured-author-count .item .name {
  color: #313131;
}